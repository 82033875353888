@charset 'UTF-8';

@import
    'abstracts/variables',
    'abstracts/mixins';

@import
    'base/base',
    'base/temp',
    'base/helpers';


// Auth
.auth-layout {
	min-height: 100vh;
	background-color: #e8e8e8
}

.auth-logo-text {
	text-align: center;
}

.auth-error-text {
	color: red;
}

.auth-button {
	width: 100%;
}

.auth-link {
	float: right;
	margin-top: 10px;
}

// Header
.trigger {
	padding: 0 24px;
	font-size: 18px;
	line-height: 64px;
	cursor: pointer;
	transition: color 0.3s;
}

.trigger:hover {
	color: #1890ff;
}

.header  {
	background: #fff;
	padding: 0;
	margin: 0;
	//line-height: 0;
}

.header-zone-btn {
	margin: 0 16px;
	float: right;
}

.lang {
	margin-right: 16px;
}

// Layout
.main-layout {
	min-height: 100vh;
}

// Content
.content {
	background: #fff;
	margin: 16px;
	padding: 24px;
}

// DrawerFooter
.drawer-footer {
	text-align: right;
}

.drawer-footer-button {
	margin-right: 8px;
}

// Table
.statistic-grid {
	background-color: #18eeff0d;
}

.table-row-cursor {
	cursor: pointer;
}

// Search
.table-search-clear-btn {
	color: rgba(0, 0, 0, 0.25);
	cursor: pointer;
	font-size: 13px;
}

.table-search-clear-btn:hover {
	color: rgba(0, 0, 0, 0.50);
	cursor: pointer;
	font-size: 13px;
}

// Card
.card-shadow {
	box-shadow: 5px 8px 24px 5px rgba(0, 0, 0, 0.06);
}

// RoleSwitcher
.role-switcher {
	width: 100%;
}

