@mixin text-overflow() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin hover() {
    transition: filter $motion;

    &:hover {
        filter: brightness(95%);
    }
}

// typography

@mixin typo-eyebrow() {
    text-transform: uppercase;
    letter-spacing: 0.075em;
    font-size: $typo-size--small;
    font-weight: $typo-weight--bold;
}