html, body {
	height: 100%;
	margin: 0;
}

html {
	box-sizing: border-box;
	font-family: $typo-family;
	font-weight: $typo-weight;
	font-size: 10px;
	color: $color-black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-size: $typo-size;
	line-height: 1.4;
}

*, *::before, *::after {
	box-sizing: inherit;
	scrollbar-color: $color-grey $color-grey--light;
	scrollbar-width: thin;
}

/*a {
	color: inherit;
	text-decoration: inherit;
}*/

button,
input {
	font-family: inherit;
	font-weight: inherit;
    font-size: inherit;
    color: inherit;
}