// typography

.h-typo-h1 {
    font-size: $typo-size--large;
    font-weight: $typo-weight--bold;
}

.h-typo-h2 {

}

.h-typo-h3 {

}

.h-typo-eyebrow {
    @include typo-eyebrow;
}
