// typography

$typo-family:               -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$typo-size--small:          1.2rem;
$typo-size:                 1.4rem;
$typo-size--large:          1.8rem;
$typo-weight:               400;
$typo-weight--bold:         700;

// colors

$color-white:               #ffffff;
$color-black:               #191919;
$color-black--light:        #888888;
$color-grey:                #cccccc;
$color-grey--light:         #e8e8e8;
$color-grey--lightest:      #f9f9f9;
$color-cta:                 #0057ff;
$color-error:               #d00d00;

// indents

$indent--small:             0.4rem;
$indent:                    0.8rem;
$indent--medium:            1.2rem;
$indent--large:             1.6rem;
$indent--extra-large:       2.4rem;

// decorations

$border-radius:             0.4rem;
$border-radius--large:      0.8rem;

$box-shadow:                0 0 $indent 0 rgba($color-black, 0.1);
$box-shadow--light:         0 0 0 0.1rem $color-grey--light;

// motion

$motion:                    0.2s;
$motion--long:              0.5s;
$motion--extra-long:        1s;

// ui

$ui-size--extra-small:      1.6rem;
$ui-size--small:            2.4rem;
$ui-size:                   3.2rem;
$ui-size--large:            4.8rem;